<template>
<div id="pageTable">
    <v-container grid-list-xl fluid class="page-header-right">
        <v-layout row wrap>
           <!-- Summary info -->
            <v-flex lg6 pa-0 pl-3 pt-3>
                <v-btn
                    :color="order.Status | order_status_color(1)"
                    width="160px"
                    height="36px"
                    small
                    dark
                    depressed
                    outlined
                  >{{ order.Status | order_status(1)}}</v-btn>
            </v-flex>
            <!-- Action buttons -->
            <v-flex lg6 pa-0 pr-6 pt-6>
                <v-layout row wrap justify-end>
                    <!-- <v-btn @click="MarkDoneOrder" v-if="orderPaymentReady">
                        Chờ thanh toán
                    </v-btn>
                    <v-btn  v-if="!canReorder" @click="CancelOrder">
                        Hủy đơn
                    </v-btn>
                    <v-btn @click="ReOrder" v-if="canReorder">
                        Lên đơn
                    </v-btn>
                    <order-flag-menu :orderID="orderId" :orderType="2"></order-flag-menu> -->
                </v-layout>
            </v-flex>
            <!-- Ghi chu don hang -->
            <v-flex sm12 md12 lg12 v-if="order.Note && order.Note != ''">
                <v-widget title="Ghi chú">
                    <div slot="widget-header-action">
                        <v-icon color="yellow darken-3">note</v-icon>
                    </div>
                    <div slot="widget-content">
                        <v-card-text class="pa-0">{{order.Note}}</v-card-text>
                    </div>
                </v-widget>
            </v-flex>
            <v-flex lg6>
                <v-widget :title="'#'+orderId">
                    <div slot="widget-header-action">
                        <h4 class="pl-2 pr-3 font-weight-bold">{{order.CreatedDate | display_date('DD-MM-YYYY HH:mm:ss') | capitalize}}</h4>
                    </div>
                <div slot="widget-content" class="customer-info-box">
                        <v-card-text>
                            <v-layout row wrap pt-1>
                                <v-flex lg4 sm4>
                                    <v-card-title class="pa-0">Người đặt hàng</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <div class="mt-4 account-toggle font-weight-bold text-red" @click="openCustomerDetail">{{customer.Username}} <span class="icon" :class="{'open':showCustomerDetail}"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i></span></div>
                                </v-flex>
                            </v-layout>
                            <v-card class="mx-auto" outlined v-if="customerView && showCustomerDetail">
                                <v-list-item three-line>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-2"><a href="javascript:void(0)" class="font-weight-bold text-red" @click="gotoCustomerDetail(customer.ID)">{{customer.Username}}</a></v-list-item-title>
                                    <v-list-item-subtitle class="mb-1">Cấp độ: {{getVipValue(customer.LevelID)}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Họ tên: {{customer.FirstName + ' ' + customer.LastName}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Email: {{customer.Email}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Điện thoại: {{`${customer.PhoneNumber}`}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Địa chỉ: {{customer.Address}}</v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                            </v-card>
                            <v-layout row wrap pt-2>
                                <v-flex lg12 sm12 :style="(!customerView || !showCustomerDetail) ? 'height: 200px' : 'height: 63px'">
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap pt-2>
                                <v-flex lg4 sm4>
                                    <v-card-title class="pa-0">Kho gửi</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <v-select :disabled="orderReadonly" class="pa-0" :items="chinaStores" v-model="orderEditable.ChinaStoreID" item-text="Name" item-value="ID"></v-select>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap pt-2>
                                <v-flex lg4 sm4>
                                    <v-card-title class="pa-0">Kho nhận</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <v-select :disabled="orderReadonly" class="pa-0" :items="warehouses" v-model="orderEditable.ReceivePlaceID" item-text="Name" item-value="ID"></v-select>
                                </v-flex>
                            </v-layout>
                            <!-- <v-layout row wrap pt-2>
                                <v-flex lg4 md4>
                                    <v-card-title class="pa-0 font-weight-bold text-red">Trạng thái đơn hàng</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <v-select class="pa-0 font-weight-bold text-red" :items="orderStatus" v-model="order.Status" item-text="name" item-value="value" disabled></v-select>
                                </v-flex>
                            </v-layout> -->
                        </v-card-text>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Danh gia don hang -->
            <v-flex v-if="(orderRole & 8)" lg6>
                <v-widget title="Tin nhắn">
                    <div slot="widget-content">
                        <message-box :orderId="orderId" :orderType="2" :disabled="orderReadonly"></message-box>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Ma van don & don hang CN -->
            <v-flex lg12>
                <v-widget title="Mã bill">
                    <div slot="widget-content">
                        <order-transaction :orderId="orderId" :orderType="1" :siteName="order.Site" :customerID="customer.ID" :customerName="customer.Username" :locked="true"></order-transaction>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Chi phi don hang -->
            <!-- <v-flex md12 lg12 sm12>
                <v-widget title="Chi phí đơn hàng">
                    <div slot="widget-content">
                        <v-layout row wrap>
                            <v-flex lg11 sm12 pa-0 ma-0>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pa-0 pt-6 pl-4>
                                        <v-checkbox label="Dịch vụ đóng gỗ" v-model="orderEditable.IsFragileGoods" :disabled="!(order.Status == statusEnum.dang_van_chuyen || paymentRole || adminRole)"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pa-0 pt-6>
                                        <v-checkbox label="Dịch vụ kiểm hàng (gói A)" v-model="isCheckPackageA" :disabled="!(order.Status == statusEnum.dang_van_chuyen || paymentRole || adminRole)"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pa-0 pt-6>
                                        <v-checkbox label="Dịch vụ kiểm hàng (gói B)" v-model="isCheckPackageB" :disabled="!(order.Status == statusEnum.dang_van_chuyen || paymentRole || adminRole)"></v-checkbox>
                                    </v-flex>
                                     <v-flex lg3 sm3 pb-0>
                                        <v-select label="Bảo hiểm hàng" v-model="orderEditable.GuaranteeType" :items="guarantees" item-text="PackageName" item-value="ID" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pb-0>
                                        <v-card-title>Tỷ giá</v-card-title>
                                    </v-flex>
                                    <v-flex lg5 sm5 pb-0 pl-2>
                                        <h-currency-input v-model="orderEditable.CurentCNYVN" label="₫" :decimal="0" :disabled="!(order.Status == statusEnum.dang_van_chuyen || adminRole)"></h-currency-input>
                                    </v-flex>
                                    <v-flex lg4 sm4 pb-0 pl-2>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap pt-3>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-title>Phí ship nội địa</v-card-title>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FeeShipCN" label="¥" :decimal="1"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.FeeShipVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-title>Phí vận chuyển (CK: {{orderEditable.FeeWeightDiscount | currency}}%)</v-card-title>
                                        <v-checkbox class="pl-3" v-model="orderEditable.IsUpdateFeeShip" label="Fix đơn giá vận chuyển" v-if="adminRole"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pt-0>
                                       <h-currency-input v-model="orderEditable.WeightPrice" label="Đơn giá (₫)" :decimal="0" :disabled="!adminRole"></h-currency-input>
                                    </v-flex>
                                     <v-flex lg3 sm3 pa-0 pl-2 pr-2>
                                        <h-currency-input v-model="order.OrderWeight" :decimal="1" :label="order.FeeShipType == 0 ? 'kg' : order.FeeShipType == 1 ? 'm3' : 'kg(quy đổi)'" disabled></h-currency-input>
                                    </v-flex>
                                    <v-flex lg3 sm3 pa-0 pl-2 pr-2>
                                        <h-currency-input v-model="order.FeeWeight" :decimal="0" label="₫" disabled></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Phí khác</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FeeOther" :decimal="0" label="Phí khác (₫)"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <v-text-field v-model="orderEditable.FeeOtherNote" label="Ghi chú" class="input-group--focused"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí bảo hiểm</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.GuaranteeAmount" :decimal="2" label="¥" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.GuaranteeAmountVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap v-if="orderEditable.IsFragileGoods">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí đóng gỗ</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FragileFee" label="¥" :decimal="1" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FragileFeeVND" :decimal="0" label="₫" disabled ></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap v-if="orderEditable.IsCheck">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí kiểm hàng</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.CheckFeeVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap v-if="orderEditable.GuaranteeType > 0">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí bảo hiểm</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.GuaranteeAmount" label="¥" :decimal="1" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.GuaranteeAmountVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phụ phí</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.Surcharge" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="purple--text" row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Chiết khấu</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.DiscountVND" :decimal="0" label="₫" :disabled="!(order.Status != statusEnum.da_thanh_toan && order.Status != statusEnum.hoan_thanh && order.Status != statusEnum.huy_don_hang && adminRole)"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap class="font-weight-bold">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Đã thanh toán</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.Payment" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap class="red--text font-weight-bold">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Cần thanh toán</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input showClass="red--text" v-model="remainAmount" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-widget>
            </v-flex> -->
            <!-- Lich su thanh toan -->
            <!-- <v-flex sm12 md12 lg12>
                <v-widget title="Lịch sử thanh toán">
                    <div slot="widget-content">
                        <v-data-table  :headers="paymentHistoryHeader" :items="order.PaymentHistory" :mobile-breakpoint="100" hide-default-footer>
                            <template  v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.CreatedDate | display_date }}</td>
                                    <td>
                                        <v-chip label small :color="item.Status | payment_type_color" text-color="white">{{ item.Status | payment_type}}</v-chip>
                                    </td>
                                    <td>{{ item.Type | payment_method }}</td>
                                    <td class="text-end">{{ item.Amount | currency }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-widget>
            </v-flex> -->
            <!-- Lich su thay doi -->
            <!-- <v-flex sm12 md12 lg12>
                <v-widget title="Lịch sử thay đổi">
                    <div slot="widget-content">
                        <v-data-table  :headers="changedHistoryHeader" :items="changedHistories" :mobile-breakpoint="100" hide-default-footer>
                            <template  v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.CreatedDate | display_date }}</td>
                                    <td>{{ item.Username}}</td>
                                    <td>{{ item.HistoryContent}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-widget>
            </v-flex> -->
        </v-layout>
    </v-container>
</div>
</template>

<script>
import VWidget from "@/components/VWidget";
import MessageBox from "@/components/chat/MessageBox";
import OrderTransaction from "@/components/widgets/OrderTransaction";
import { sendErrorNotice } from "@/commons/utils";
// import OrderFlag from "@/components/widgets/OrderFlag";
import { mapState } from "vuex";
import configs from "@/configs";
import _ from "lodash";
import {
    payment_type,
    payment_type_color,
    complain_type,
    complain_type_color
} from "@/commons/hpod";

export default {
    filters: {
        payment_type,
        payment_type_color,
        complain_type,
        complain_type_color,
        payment_method: function (value) {
            return value == 1 ? "Trực tiếp" : "Ví điện tử";
        },

    },
    components: {
        VWidget,
        MessageBox,
        // 'order-flag-menu': OrderFlag,
        'order-transaction': OrderTransaction
    },
    data() {
        return {
            isCheckPackageA: false,
            isCheckPackageB: false,
            showCustomerDetail: false,
            tempOrder: {},
            dialogProduct: false,
            message: "",
            timeout: 100000,
            events: [],
            input: null,
            nonce: 0,
            search: "",
            statusEnum: configs.ORDER_DEPOSIT_STATUS_ENUM,
            editedProIndex: -1,
            editedPro: {
                quantity: 0,
                price: 0
            },
            orderEditable: {
                OrderID: 0,
                FeeShipCN: 0,
                ChinaStoreID: 0,
                ReceivePlaceID: 0,
                CurentCNYVN: 0,
                WeightPrice: 0,
                FeeOther: 0,
                FeeOtherNote: '',
                IsFragileGoods: false,
                IsCheck: false,
                FragileFee: 0,
                FragileFeeVND: 0,
                CheckFee: 0,
                CheckFeeVND: 0,
                DiscountVND: 0,
                GuaranteeType: 0,
                IsUpdateFeeShip: 0,
                Status: 0
            },
            totalRealAmount: 0,
            paymentHistoryHeader: [{
                    text: "Ngày thanh toán",
                    value: "CreatedDate",
                    sortable: false
                },
                {
                    text: "Loại thanh toán",
                    value: "Status",
                    sortable: false
                },
                {
                    text: "Hình thức thanh toán",
                    value: "Type",
                    sortable: false
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    sortable: false,
                    align: "end"
                }
            ],
            changedHistoryHeader: [{
                    text: "Ngày thay đổi",
                    value: "CreatedDate",
                    sortable: false
                },
                {
                    text: "Người thay đổi",
                    value: "Username",
                    sortable: false
                },
                {
                    text: "Nội dung",
                    value: "HistoryContent",
                    sortable: false
                }
            ],
        };
    },
    computed: {
        orderId() {
            return Number(this.$route.params.Pid);
        },
        customerId() {
            return this.$route.query.uid && this.$route.query.uid != '' ? Number(this.$route.query.uid) : this.order.UID;
        },
        orderStatus() {
            return configs.ORDER_DEPOSIT_STATUS;
        },
        orderPaymentReady() {
            return this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.da_nhap_kho_vn;
        },
        canReorder() {
            return this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.huy_don_hang;
        },
        orderReadonly() {
            return this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.huy_don_hang || this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.hoan_thanh;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        customerView() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Manager) == configs.ROLE_TYPE.Manager);
        },
        orderRole() {
            return this.$store.state.authentication.role.Orders;
        },
        orderEditRole() {
            return this.$store.state.authentication.role.OrderEditable;
        },
        ...mapState({
            order: state => state.deposit.selected,
            customer: state => state.customer.selected,
            vipLevels: state => state.customerLevel.all.data,
            changedHistories: state => state.changedHistories.all.data,
            warehouses: state => state.commons.stores,
            chinaStores: state => state.commons.chinaStores,
            guarantees: state => {
                let tmp = [{PackageName: 'Không sử dụng', ID: 0}];
                if(state.feeGuarantee.all.data) {
                state.feeGuarantee.all.data.forEach(element => {
                    tmp.push(element);
                });
                }
                return tmp;
            },
            allFlags: state => state.orderFlag.all.data,
        }),
        ...mapState("deposit", {
            loading: "loading",
        }),
        remainAmount() {
            return this.order.TotalPriceVND - this.order.Payment;
        },
    },
    watch: {
        orderId() {
            this.refreshData();
        },
        order() {
            this.orderEditable = {
                OrderID: this.order.ID,
                FeeShipCN: this.order.FeeShipCN,
                ChinaStoreID: this.order.ChinaStoreID,
                ReceivePlaceID: this.order.ReceivePlaceID,
                CurentCNYVN: this.order.CurentCNYVN,
                WeightPrice: this.order.WeightPrice,
                FeeOther: this.order.FeeOther,
                FeeOtherNote: this.order.FeeOtherNote,
                IsFragileGoods: this.order.IsFragileGoods,
                IsCheck: this.order.IsCheck,
                CheckFeeType: this.order.CheckFeeType,
                FragileFee: this.order.FragileFee,
                FragileFeeVND: this.order.FragileFeeVND,
                CheckFee: this.order.CheckFee,
                CheckFeeVND: this.order.CheckFeeVND,
                DiscountVND: this.order.DiscountVND,
                GuaranteeType: this.order.GuaranteeType,
                IsUpdateFeeShip: this.order.IsUpdateFeeShip,
                Status: this.order.Status
            };
            this.tempOrder = {...this.orderEditable};
            this.isCheckPackageA = this.order.IsCheck && this.order.CheckFeeType == 1;
            this.isCheckPackageB = this.order.IsCheck && this.order.CheckFeeType == 2;
        },
        // orderEditable: {
        //     handler: function (newVal, oldVal) {
        //         this.updateOrderDetail(newVal, oldVal);
        //     },
        //     deep: true,
        //     immediate: true
        // },
        isCheckPackageA() {
            if(this.isCheckPackageA) {
                this.isCheckPackageB = false;
                this.orderEditable.IsCheck = 1;
                this.orderEditable.CheckFeeType = 1;
            }
            if(!this.isCheckPackageA && !this.isCheckPackageB) {
                this.orderEditable.IsCheck = 0;
                this.orderEditable.CheckFeeType = 0;
            }
        },
        isCheckPackageB() {
            if(this.isCheckPackageB) {
                this.isCheckPackageA = false;
                this.orderEditable.IsCheck = 1;
                this.orderEditable.CheckFeeType = 2;
            }
            if(!this.isCheckPackageA && !this.isCheckPackageB) {
                this.orderEditable.IsCheck = 0;
                this.orderEditable.CheckFeeType = 0;
            }
        }
    },
    methods: {
        closePanel() {
            this.$router.push({
                name: "OrderList"
            });
        },
        refreshData() {
            this.orderEditable.OrderID = 0;
            this.$store.dispatch("deposit/getDetail", this.orderId);
            this.$store.dispatch("feeGuarantee/getList");
            this.$store.dispatch("orderFlag/getList", {'OrderID': this.orderId, 'OrderType': configs.ORDER_TYPE.deposit});
            this.$store.dispatch("customerLevel/getCustomerLevelList");
            if(this.customerId) {
                this.$store.dispatch("customer/getCustomerDetail", this.customerId);
            }
            var param = {
                'orderId': this.orderId,
                'orderType': 2
            };
            this.$store.dispatch("changedHistories/getList", param);
        },
        updateOrderDetail: _.debounce(function (newVal, oldVal) {
            if (oldVal && oldVal.OrderID !== 0) {
                if(!_.isEqual(newVal, this.tempOrder)){
                    this.tempOrder = {...newVal};
                    this.$store.dispatch("deposit/setDetail", {data: newVal});
                }
            }
        }, 1000),
        getVipValue(id) {
            const item = _.find(this.vipLevels, {'ID': id});
            return item ? item.VIPName : '';
        },
        CancelOrder() {
            if(this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.dang_van_chuyen) {
                this.orderEditable.Status = configs.ORDER_DEPOSIT_STATUS_ENUM.huy_don_hang;
            }
            else {
                sendErrorNotice("Không thể hủy đơn hàng!");
            }
        },
        MarkDoneOrder() {
            if(this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.da_nhap_kho_vn) {
                this.orderEditable.Status = configs.ORDER_DEPOSIT_STATUS_ENUM.cho_thanh_toan;
            }
            else {
                sendErrorNotice("Không đổi trạng thái đơn hàng!");
            }
        },
        ReOrder() {
            if(this.order.Status == configs.ORDER_DEPOSIT_STATUS_ENUM.huy_don_hang) {
                this.orderEditable.Status = configs.ORDER_DEPOSIT_STATUS_ENUM.dang_van_chuyen;
            }
            else {
                sendErrorNotice("Không đổi trạng thái đơn hàng!");
            }
        },
        openCustomerDetail: function() {
            this.showCustomerDetail = !this.showCustomerDetail;
        },
        gotoCustomerDetail: function(id) {
            let routeData = this.$router.resolve({
                name: "CustomerDetail",
                params: {
                Pid: id,
                title: `Khách hàng (#${id})`
                }
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.$store.dispatch("commons/getAllStores");
        this.$store.dispatch("commons/getAllChinaStores");
        this.refreshData();
    },
};
</script>

<style scoped>
.product-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.v-list--two-line .v-list__tile {
    height: auto !important;
    padding: 5px 0 5px 0;
}

.theme--light.red--text.v-input--is-disabled input {
    color: #f44336 !important;
    caret-color: #f44336 !important;
}

.fee-cn-footer .v-text-field .v-label--active {
    right: 0px !important;
    left: auto !important;
}

.fee-cn-footer .v-text-field__slot input {
    text-align: right !important;
    font-size: 0.9em !important;
}

.image-product {
    width: 64px;
}

.text-red .v-select__selection,
.text-red .v-icon.v-icon {
    color: #a77800!important;
}
</style>
